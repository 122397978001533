/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers','local'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'BK-preprod',
        clientId: 'vi-bk-ecosysteme',
        buttonText: 'Connexion Burger King',
      },
    ],
  ],
  authLocalOptions: {
    mode: 'otp',
  },
}
