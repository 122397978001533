<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="isHomeActive">
      <v-sheet
        color="#198737"
        :style="{
          backgroundImage: `url(${require('/assets/images/bandeau-bg.png')})`,
        }"
        dark
        class="pa-3"
      >
        <div>
          <v-btn
            rounded
            depressed
            color="#ffaa00"
            class="ma-2 custom-color"
            @click="
              handleOpen(
                'https://www.bklaradio.fr/?utm_source=bkonair&utm_medium=event&utm_campaign=event&utm_id=bklaradio'
              )
            "
          >
            <img
              :src="require('~/assets/images/play.svg')"
              height="32"
              alt="play"
              class="pr-1"
            />
            Ecouter BK LA RADIO
          </v-btn>
          Mot de passe : bklaradio
        </div>
      </v-sheet>
    </v-container>
    <v-container v-if="isUniverse('61adf77c4bc5fe344f08d4d2')">
      <v-sheet color="#c4302b" dark class="pa-3">
        <div>
          Retrouvez également l'intégralité de nos campagnes de pub sur notre
          chaîne YouTube
          <v-btn
            rounded
            depressed
            color="#ffaa00"
            class="ma-2 custom-color"
            @click="
              handleOpen(
                'https://www.youtube.com/channel/UCOUYCp_k1mHGwXAz3uRpjyw/videos'
              )
            "
          >
            <img
              :src="require('~/assets/images/play-yt.svg')"
              height="24"
              alt="Play Youtube"
              class="pr-1"
            />
            Découvrir
          </v-btn>
        </div>
      </v-sheet>
    </v-container>
    <w-block name="cta" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
    isUniverse(id) {
      return (
        this.$route.name === 'universe' && this.$route.params.universe === id
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-family: Flame !important;
}
.custom-color {
  color: #502314;
}
.subtitle-1 {
  font-weight: bold;
  color: #ffffff;
}
</style>
