<template>
  <div class="w-block__post-toolbar-1">
    <v-container v-if="$route.name === 'index'" class="pa-4">
      <v-sheet
        color="#f8f1df"
        :style="{
          backgroundImage: `url(${require('/assets/images/bandeau-bg2.png')})`,
        }"
        dark
        class="pa-3"
      >
        <v-flex xs12>
          <div class="ma-2 custom-color">
            Pour accéder à mon intranet, c'est par là
            <v-btn
              rounded
              depressed
              color="#f8f1df"
              class="ma-0 pa-1"
              @click="
                handleOpen(
                  'https://burgerking365.sharepoint.com/Intranet/SitePages/MIAM.aspx'
                )
              "
            >
              <img
                :src="require('~/assets/images/logo-miam.svg')"
                height="32"
                alt="play"
                class="pr-1"
              />
            </v-btn>
          </div>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.w-block__post-toolbar-1 {
  justify-content: center;
  text-align: center;
  font-family: Flame !important;
}
.v-btn__content {
  background-color: transparent;
}
.v-btn::before {
  background-color: transparent;
}
.v-card {
  border-radius: 8px;
}
.custom-color {
  color: #502314;
}
</style>
