/**
 * configuration applied when BROCOLI_API_MODE = production
 */
 module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'BK',
        clientId: 'vi-bk-ecosysteme',
        buttonText: 'Connexion Burger King',
      },
    ],
  ],

}
